import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 677.000000 665.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,665.000000) scale(0.100000,-0.100000)">
<path d="M0 3325 l0 -3325 3360 0 3360 0 0 3325 c0 3318 0 3325 -20 3325 -20
0 -20 -7 -20 -3317 l0 -3318 -3320 0 -3320 0 0 3318 c0 3310 0 3317 -20 3317
-20 0 -20 -7 -20 -3325z"/>
<path d="M80 3340 l0 -3280 3280 0 3280 0 0 3280 0 3280 -3280 0 -3280 0 0
-3280z m3908 1416 c342 -344 622 -628 622 -633 0 -9 -72 -86 -133 -143 l-37
-34 -155 154 -155 155 0 -617 0 -616 -121 -121 c-66 -67 -123 -121 -125 -121
-2 0 -4 191 -4 425 l0 425 -197 -2 -198 -3 -3 -624 -2 -624 -59 -62 -59 -61
-61 61 -61 62 -2 624 -3 624 -197 3 -198 2 -1 -412 c-1 -227 -1 -416 0 -420 3
-21 -23 1 -131 109 l-118 118 0 615 0 615 -148 -147 c-81 -81 -151 -151 -155
-155 -8 -8 -179 157 -175 169 5 12 1240 1258 1248 1258 3 0 286 -281 628 -624z
m-2645 -2769 c50 -54 257 -239 258 -231 0 5 0 62 -1 127 l-1 118 41 -3 41 -3
2 -213 c1 -116 0 -212 -1 -212 -2 0 -65 55 -139 123 -74 67 -139 124 -144 125
-5 2 -9 -48 -9 -117 l0 -121 -40 0 -40 0 0 215 c0 118 2 215 5 215 3 0 16 -11
28 -23z m1286 -160 c41 -59 78 -107 82 -107 4 0 55 66 113 146 l106 146 0
-216 0 -216 -40 0 -40 0 0 95 c0 52 -3 95 -7 95 -5 -1 -35 -41 -68 -90 -33
-49 -62 -90 -65 -89 -3 0 -32 38 -65 86 l-60 86 -3 -91 -3 -92 -39 0 -40 0 0
217 0 216 28 -40 c15 -22 60 -88 101 -146z m638 118 c12 -27 38 -85 57 -128
20 -44 36 -81 36 -84 0 -3 14 -34 30 -70 17 -35 30 -69 30 -74 0 -5 -19 -9
-42 -9 -41 0 -44 2 -60 40 l-17 40 -71 0 -72 0 -15 -40 c-15 -37 -19 -40 -54
-40 l-38 0 43 103 c100 234 141 325 147 319 3 -4 15 -29 26 -57z m1201 -25
c17 -41 56 -131 86 -199 31 -69 56 -128 56 -133 0 -4 -20 -8 -43 -8 -42 0 -45
2 -62 40 l-18 40 -69 0 -70 0 -15 -40 c-15 -37 -19 -40 -55 -40 -21 0 -38 4
-38 9 0 5 13 37 29 72 16 35 45 100 64 144 81 186 90 205 97 198 4 -4 21 -42
38 -83z m866 -58 c31 -73 72 -166 91 -207 l34 -75 -49 0 c-49 0 -49 0 -61 40
l-12 40 -74 0 -73 0 -16 -40 c-15 -37 -19 -40 -55 -40 -21 0 -39 1 -39 3 0 2
8 21 18 43 10 21 52 116 93 211 41 94 77 169 80 165 4 -4 32 -67 63 -140z
m-3404 -72 l0 -210 -40 0 -40 0 0 210 0 210 40 0 40 0 0 -210z m199 140 l-1
-70 -34 0 -34 0 0 70 0 70 35 0 35 0 -1 -70z m206 -140 l0 -211 -40 3 -40 3 0
190 c0 105 2 198 4 208 2 12 13 17 40 17 l36 0 0 -210z m1711 174 c70 -43 97
-98 92 -191 -3 -62 -7 -76 -34 -110 -52 -64 -83 -76 -206 -81 l-108 -5 0 213
0 213 105 -5 c93 -4 109 -8 151 -34z m944 -4 l0 -40 -53 0 -53 0 1 -167 0
-168 -42 -3 -43 -3 0 170 0 171 -44 0 -44 0 -4 40 -4 40 143 0 143 0 0 -40z
m-2895 -487 l24 -28 1 28 c0 15 5 27 10 27 6 0 10 -20 10 -45 0 -52 -11 -56
-45 -17 l-24 27 -1 -27 c0 -16 -4 -28 -10 -28 -5 0 -10 20 -10 45 0 52 11 56
45 18z m95 -18 c0 -25 -4 -45 -10 -45 -5 0 -10 20 -10 45 0 25 5 45 10 45 6 0
10 -20 10 -45z m60 0 c0 -25 -4 -45 -10 -45 -5 0 -10 20 -10 45 0 25 5 45 10
45 6 0 10 -20 10 -45z m69 18 c12 -27 13 -27 27 -8 8 10 14 22 14 27 0 4 7 8
15 8 11 0 15 -12 15 -45 0 -49 -16 -62 -23 -17 -4 26 -4 26 -12 5 -4 -13 -13
-23 -20 -23 -7 0 -16 10 -20 23 -8 21 -8 21 -12 -5 -7 -45 -23 -32 -23 17 0
52 20 61 39 18z m173 -15 c20 -47 18 -54 -11 -38 -15 8 -27 8 -42 0 -11 -7
-22 -10 -24 -8 -7 8 36 88 47 88 7 0 21 -19 30 -42z m128 23 c5 -11 10 -24 10
-30 0 -19 -32 -41 -61 -41 -28 0 -29 2 -29 45 0 45 0 45 35 45 24 0 37 -6 45
-19z m102 -23 c19 -44 18 -55 -4 -42 -20 13 -48 15 -48 4 0 -6 -7 -10 -15 -10
-12 0 -11 8 7 45 12 25 27 45 32 45 6 0 19 -19 28 -42z m106 28 c-11 -8 -18
-26 -18 -45 0 -17 -4 -31 -10 -31 -5 0 -10 16 -10 35 0 24 -5 35 -15 35 -8 0
-15 5 -15 10 0 6 19 10 43 10 37 -1 40 -2 25 -14z m101 -28 c10 -24 17 -44 16
-46 -2 -2 -12 3 -24 10 -17 11 -25 11 -42 0 -12 -7 -23 -12 -25 -9 -8 8 32 87
44 87 7 0 21 -19 31 -42z m61 18 c0 -37 17 -41 25 -6 9 39 22 38 38 -2 l14
-33 7 33 c4 17 12 32 17 32 12 0 11 -5 -3 -51 -14 -48 -34 -50 -44 -6 l-7 32
-14 -32 c-7 -18 -17 -33 -22 -33 -5 0 -16 20 -24 45 -14 39 -14 45 -1 45 8 0
14 -10 14 -24z m204 -17 c19 -46 15 -59 -10 -38 -10 8 -18 14 -19 14 -1 0 -9
-6 -19 -14 -9 -8 -19 -12 -22 -9 -9 9 28 88 41 88 7 0 20 -19 29 -41z m67 19
l12 -23 10 23 c6 12 14 22 19 22 5 0 8 -3 6 -7 -2 -5 -10 -25 -18 -45 -14 -39
-30 -50 -30 -23 0 9 -7 29 -15 45 -9 17 -11 30 -6 30 6 0 16 -10 22 -22z m184
2 c12 -13 15 -27 11 -45 -5 -21 -12 -25 -44 -26 -33 -1 -34 -2 -12 -9 24 -8
24 -8 -7 -9 -22 -1 -37 6 -47 21 -21 30 -20 44 4 68 25 25 72 26 95 0z m115
11 c0 -5 -12 -11 -27 -13 -20 -2 -28 -9 -28 -23 0 -23 34 -33 46 -14 10 17 23
4 16 -16 -8 -18 -58 -20 -75 -3 -7 7 -12 21 -12 33 0 12 5 26 12 33 14 14 68
16 68 3z m79 -18 l13 -28 15 28 c20 39 39 29 36 -18 -3 -46 -13 -60 -13 -19
l0 29 -20 -25 c-20 -26 -21 -26 -38 5 -11 19 -11 18 -12 -7 0 -16 -4 -28 -10
-28 -5 0 -10 20 -10 45 0 52 20 61 39 18z m169 -18 c15 -32 18 -45 9 -45 -7 0
-18 5 -24 11 -8 8 -17 8 -33 -1 -12 -6 -24 -10 -26 -7 -7 7 31 87 42 87 5 0
20 -20 32 -45z m62 0 c0 -25 -4 -45 -10 -45 -5 0 -10 20 -10 45 0 25 5 45 10
45 6 0 10 -20 10 -45z m60 4 c0 -19 5 -29 14 -29 8 0 18 -4 21 -10 4 -6 -7
-10 -24 -10 -30 0 -31 1 -31 46 0 27 4 43 10 39 6 -3 10 -19 10 -36z m185 32
c3 -5 -9 -11 -27 -13 -25 -2 -33 -8 -33 -23 0 -14 8 -21 28 -23 36 -4 35 -22
-1 -22 -33 0 -52 16 -52 45 0 12 5 26 12 33 14 14 64 16 73 3z m109 -7 c33
-32 13 -74 -35 -74 -47 0 -71 58 -35 82 19 13 54 9 70 -8z m80 -11 l14 -28 13
28 c19 43 39 34 39 -18 0 -25 -4 -45 -10 -45 -5 0 -10 10 -10 22 0 21 0 21
-15 2 -15 -19 -15 -19 -30 0 -15 19 -15 19 -15 -2 0 -23 -14 -30 -25 -12 -8
13 5 80 16 80 5 0 15 -12 23 -27z m-299 -53 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-1837 -112 c7 -7 12 -22 12 -34 0 -51
-72 -61 -78 -11 -2 16 -1 35 2 43 7 17 48 18 64 2z m105 -10 c18 -27 -7 -68
-40 -68 -29 0 -22 15 12 23 16 4 14 5 -6 6 -29 1 -42 21 -32 47 7 20 52 15 66
-8z m108 1 c17 -33 -4 -71 -38 -67 -36 4 -50 29 -35 62 15 33 56 35 73 5z m69
15 c0 -3 -5 -14 -10 -25 -7 -11 -7 -19 -1 -19 5 0 12 6 14 13 8 19 26 -11 20
-34 -6 -23 -23 -25 -23 -3 0 10 -6 14 -15 11 -21 -9 -27 8 -14 37 11 23 29 36
29 20z m120 -4 c0 -5 -10 -10 -22 -11 l-23 0 23 -10 c34 -13 27 -53 -10 -57
-16 -2 -28 1 -28 7 0 6 9 11 20 11 11 0 20 4 20 9 0 5 -9 11 -20 14 -12 3 -20
14 -20 26 0 17 6 21 30 21 17 0 30 -4 30 -10z m60 -35 c0 -25 -4 -45 -10 -45
-5 0 -10 15 -10 33 0 18 -5 38 -12 45 -9 9 -7 12 10 12 19 0 22 -5 22 -45z
m100 35 c0 -16 -33 -80 -42 -80 -11 0 -10 7 8 42 l16 31 -26 -7 c-19 -5 -26
-3 -26 9 0 11 10 15 35 15 19 0 35 -4 35 -10z m60 -9 c-14 -26 -13 -36 3 -23
19 16 38 -10 22 -31 -15 -21 -25 -22 -25 -2 0 8 -9 15 -20 15 -24 0 -25 4 -4
35 19 29 39 34 24 6z m120 -7 c-16 -42 -30 -64 -41 -64 -5 0 -3 13 6 30 19 37
19 42 -2 39 -28 -2 -35 0 -28 11 3 6 22 10 40 10 33 0 34 -1 25 -26z m75 6
c-17 -19 -17 -20 -1 -20 12 0 16 -7 14 -22 -3 -26 -43 -37 -61 -19 -8 8 -3 11
16 11 15 0 27 5 27 10 0 6 -7 10 -16 10 -10 0 -14 6 -10 15 3 8 1 15 -4 15 -6
0 -10 5 -10 10 0 6 14 10 32 10 30 0 31 0 13 -20z m102 5 c3 -9 -3 -25 -12
-35 -16 -18 -16 -20 -2 -20 9 0 19 -4 22 -10 4 -6 -10 -10 -35 -10 l-42 0 28
33 c27 31 27 32 6 35 -31 5 -27 22 3 22 14 0 28 -7 32 -15z m135 -2 c53 -48
-27 -123 -86 -80 -11 8 -18 26 -18 42 0 50 65 74 104 38z m119 -4 c17 -33 -4
-71 -38 -67 -36 4 -50 29 -35 62 15 33 56 35 73 5z m99 11 c0 -22 -35 -80 -48
-80 -12 0 -12 4 2 25 22 33 20 48 -4 42 -13 -3 -20 0 -20 9 0 9 12 14 35 14
19 0 35 -4 35 -10z m88 1 c8 -5 12 -22 10 -42 -2 -27 -8 -35 -26 -37 -29 -4
-52 14 -52 42 0 39 36 58 68 37z m107 -1 c-3 -5 -16 -10 -28 -11 -20 -1 -20
-2 3 -8 18 -5 25 -14 25 -31 0 -21 -5 -25 -30 -25 -25 0 -30 4 -33 28 -2 16 2
35 9 43 13 16 64 20 54 4z m85 1 c0 -6 -5 -13 -11 -16 -6 -5 -4 -12 7 -20 23
-17 11 -45 -20 -45 -14 0 -28 5 -31 10 -4 6 5 10 19 10 14 0 26 5 26 10 0 6
-7 10 -16 10 -11 0 -14 6 -9 21 5 14 3 19 -3 15 -6 -3 -13 -2 -17 4 -4 6 6 10
24 10 17 0 31 -4 31 -9z m95 -16 c1 -11 4 -25 7 -30 12 -19 -8 -37 -38 -33
-26 3 -29 6 -27 38 0 19 1 39 2 43 1 5 13 7 29 5 19 -2 27 -9 27 -23z m105 15
c0 -5 -10 -10 -22 -11 l-23 0 23 -10 c33 -13 27 -53 -10 -57 -30 -4 -43 20
-32 62 5 20 12 26 35 26 16 0 29 -4 29 -10z m100 7 c0 -13 -43 -87 -50 -87
-13 0 -13 6 6 42 l16 31 -26 -7 c-19 -5 -26 -3 -26 9 0 11 11 15 40 15 22 0
40 -1 40 -3z m96 -21 c11 -45 2 -61 -36 -61 -31 0 -35 3 -38 28 -5 43 4 57 37
57 24 0 32 -5 37 -24z m106 -2 c16 -34 0 -59 -37 -59 -37 0 -53 25 -37 59 8
18 19 26 37 26 18 0 29 -8 37 -26z m100 -9 c5 -46 -3 -57 -38 -53 -26 3 -29 7
-30 38 -1 46 2 50 35 50 25 0 29 -4 33 -35z"/>
<path d="M3033 4698 l-193 -193 0 -313 0 -312 190 0 c105 0 193 0 198 0 9 0 9
155 2 643 l-5 367 -192 -192z"/>
<path d="M3489 4573 c-1 -175 -3 -402 -4 -505 l-3 -188 199 0 199 0 0 313 0
312 -191 193 c-105 105 -193 192 -195 192 -2 0 -5 -143 -5 -317z"/>
<path d="M3350 2310 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3210 1783 c-8 -21 -16 -41 -18 -45 -2 -5 15 -8 37 -8 23 0 41 4 41
9 0 16 -30 81 -38 81 -4 0 -14 -17 -22 -37z"/>
<path d="M4398 1775 l-17 -45 40 0 c23 0 39 3 37 8 -2 4 -10 24 -18 45 -8 20
-17 37 -20 37 -3 0 -13 -20 -22 -45z"/>
<path d="M5251 1807 c-6 -15 -15 -38 -21 -52 -9 -24 -8 -25 30 -25 22 0 40 4
40 9 0 5 -9 28 -19 52 l-20 43 -10 -27z"/>
<path d="M3870 1790 l0 -130 46 0 c61 0 109 24 128 65 30 63 14 131 -40 174
-20 15 -41 21 -80 21 l-54 0 0 -130z"/>
<path d="M2455 1460 c-8 -13 -1 -24 14 -19 6 3 7 10 2 18 -6 10 -10 10 -16 1z"/>
<path d="M2555 1455 c0 -19 5 -25 21 -25 23 0 34 16 27 38 -3 6 -14 12 -26 12
-17 0 -22 -6 -22 -25z"/>
<path d="M2685 1460 c-8 -13 -1 -24 14 -19 6 3 7 10 2 18 -6 10 -10 10 -16 1z"/>
<path d="M2886 1455 c-3 -9 0 -15 9 -15 16 0 20 16 6 24 -5 3 -11 -1 -15 -9z"/>
<path d="M3156 1455 c-3 -9 0 -15 9 -15 9 0 12 6 9 15 -4 8 -7 15 -9 15 -2 0
-5 -7 -9 -15z"/>
<path d="M3388 1483 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3432 1445 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M3351 1443 c-1 -12 4 -25 9 -28 12 -7 12 2 0 30 -8 19 -9 19 -9 -2z"/>
<path d="M3380 1445 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M3766 1455 c-3 -9 0 -15 9 -15 16 0 20 16 6 24 -5 3 -11 -1 -15 -9z"/>
<path d="M4160 1455 c0 -23 4 -26 28 -23 19 2 27 9 27 23 0 14 -8 21 -27 23
-24 3 -28 0 -28 -23z"/>
<path d="M2120 1275 c0 -18 5 -25 20 -25 15 0 20 7 20 25 0 18 -5 25 -20 25
-15 0 -20 -7 -20 -25z"/>
<path d="M2230 1285 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M2324 1285 c-7 -18 3 -35 21 -35 15 0 28 27 19 41 -8 14 -34 11 -40
-6z"/>
<path d="M3178 1303 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3137 1283 c-14 -13 -7 -43 12 -53 24 -12 51 -13 51 -1 0 5 -8 7 -18
4 -22 -5 -46 21 -38 42 6 16 3 19 -7 8z"/>
<path d="M3210 1263 c0 -21 3 -24 9 -14 5 8 7 20 4 28 -8 21 -13 15 -13 -14z"/>
<path d="M3160 1265 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M3284 1285 c-7 -18 3 -35 21 -35 15 0 28 27 19 41 -8 14 -34 11 -40
-6z"/>
<path d="M3480 1275 c0 -18 5 -25 20 -25 15 0 20 7 20 25 0 18 -5 25 -20 25
-15 0 -20 -7 -20 -25z"/>
<path d="M3596 1273 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"/>
<path d="M3775 1290 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M3775 1250 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M3876 1273 c-11 -11 -6 -23 8 -23 8 0 17 4 20 9 7 10 -19 23 -28 14z"/>
<path d="M4067 1294 c-16 -16 -7 -44 14 -44 15 0 20 5 17 22 -3 22 -19 33 -31
22z"/>
<path d="M4164 1285 c-7 -18 3 -35 21 -35 15 0 24 19 18 38 -6 17 -32 15 -39
-3z"/>
<path d="M4275 1290 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M4278 1253 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
